<template>
    <main>
        <page-header-compact>Usuarios</page-header-compact>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <data-grid :data="pagination" @search="index()">
                        <template #action-button>
                            <nuevoUsuarioComponent></nuevoUsuarioComponent>
                        </template>
                        <template #grid>
                            <grid-table>
                                <template #head>
                                    <grid-row>
                                        <grid-cell-header v-for="(h, i) in headers" :key="`${h}${i}`">
                                            {{h}}
                                        </grid-cell-header>
                                    </grid-row>
                                </template>
                                <template #body>
                                    <grid-row
                                        v-for="(usuarios, index) in pagination.data"
                                        :key="index"
                                    >
                                        <grid-cell class="text-center">{{ (usuarios.tipo_documento || {}).codigo }}</grid-cell>
                                        <grid-cell class="text-center">{{ usuarios.documento }}</grid-cell>
                                        <grid-cell class="text-center">
                                            {{ usuarios.nombre_1 }} {{ usuarios.nombre_2 }} {{ usuarios.apellido_1 }} {{ usuarios.apellido_2 }}
                                        </grid-cell>
                                        <grid-cell class="text-center">{{ usuarios.sexo }}</grid-cell>
                                        <grid-cell class="text-center">{{ usuarios.fecha_nacimiento | formatDate }}</grid-cell>
                                        <grid-cell class="text-center">{{ usuarios.telefono }}</grid-cell>
                                        <grid-cell class="text-center">
                                            <button
                                                class="btn btn-transparent-dark btn-sm"
                                                @click="editar(usuarios.id)"
                                                title="Editar">
                                                <i class="fas fa-user-edit"></i>
                                            </button>
                                        </grid-cell>
                                        <grid-cell class="text-center">
                                            <router-link class="btn btn-transparent-dark btn-sm" :to="'/verUsuario/' + usuarios.id" >
                                                <i class="far fa-eye"></i>
                                            </router-link>
                                        </grid-cell>
                                    </grid-row>
                                </template>
                            </grid-table>
                        </template>
                    </data-grid>
                </div>
            </div>
        </div>
        <editar-usuario-component ref="editar_usuario"></editar-usuario-component>
    </main>
</template>

<script>

import EditarUsuarioComponent from "./EditarUsuarioEvt.vue";
import nuevoUsuarioComponent from "./NuevoUsuarioEvt.vue";
import PageHeaderCompact from "../../components/layouts/content/page-header-compact";
import usuarioService from "../../services/usuarioService";
import paginateMixin from "../../mixins/PaginateMixin";
import GridTable from "../../components/common/utilities/DataGrid/GridTable";
import GridRow from "../../components/common/utilities/DataGrid/GridRow";
import GridCellHeader from "../../components/common/utilities/DataGrid/GridCellHeader";
import GridCell from "../../components/common/utilities/DataGrid/GridCell";
import DataGrid from "../../components/common/utilities/DataGrid/DataGrid";

export default {
    components: {
        DataGrid,
        GridCell,
        GridCellHeader,
        GridRow,
        GridTable,
        PageHeaderCompact,
        nuevoUsuarioComponent,
        EditarUsuarioComponent,
    },
    mixins : [paginateMixin(usuarioService)],
    data(){
        return {
            headers : [
                'Tipo',
                'Documento',
                'Nombre',
                'Genero',
                'F .Nacimiento',
                'Telefono',
                '',
                ''
            ],
            pagination : {
                per_page : 10
            }
        }
    },
    created() {
        this.index()
    },
    methods : {
        editar(id){
            this.$nextTick(async () => {
                this.LoaderSpinnerShow();
                await this.$refs.editar_usuario.open(id);
                this.LoaderSpinnerHide();
            });
        }
    }
};

</script>

